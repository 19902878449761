import React from 'react'
import * as Sentry from '@sentry/react'

import { hot } from 'react-hot-loader/root'

import universal from 'react-universal-component'

import PageProgress from './app/components/PageProgress'

import 'nprogress/nprogress.css?raw'
import './loader.less?raw'

const AppLoader = universal(props => import('./wrapper'), {
  loading: () => (
    <PageProgress>
      <div id='splash-logo' />
    </PageProgress>
  ),
  error: () => (
    <div id='splash-error'>{`An error occurred while loading ${CONFIG.frontend.appName}. Please refresh the page.`}</div>
  ),
  onError: error => {
    console.error(error)
    Sentry.captureException(error)
  }
})

export default hot(AppLoader)
