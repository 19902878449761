import 'core-js'
import 'regenerator-runtime/runtime'
import 'react-hot-loader'
import './beta'

import * as Sentry from '@sentry/react'

import AppLoader from './loader'
import React from 'react'
import ReactDOM from 'react-dom'

// Handle uncaught exceptions
Sentry.init({
  ...CONFIG.logs.sentry,
  dsn: CONFIG.logs.sentry.dsn.frontend,
  captureUnhandledRejections: false,
  ignoreErrors: [
    'jwt expired', // feathers authentication
    /ResizeObserver/i // react-sizeme
  ],
  // See https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/redux/#normalization-depth
  normalizeDepth: 10
})

ReactDOM.render(<AppLoader />, document.getElementById('index'))
