const KEY_BETA_FEATURES = 'enableBetaFeatures'

const enableBetaFeatures = !!window.localStorage.getItem(KEY_BETA_FEATURES)

if (enableBetaFeatures) {
  window.disableBetaFeatures = () => {
    window.localStorage.removeItem(KEY_BETA_FEATURES)
    window.console.log('Beta features DISABLED')
    window.location.reload()
  }
} else {
  let viewedBetaDisclaimer = false

  window.enableBetaFeatures = () => {
    if (viewedBetaDisclaimer) {
      window.localStorage.setItem(KEY_BETA_FEATURES, 1)
      window.console.log('Beta features ENABLED')
      window.location.reload()
    } else {
      window.console.log(
        '%cWarning: beta features are experimental and may lead to data loss. Use this option at your own risk. Run the command again to dismiss this message and enable beta features.',
        'background: red; color: yellow; font-size: x-large'
      )
      viewedBetaDisclaimer = true
    }
  }
}

export default enableBetaFeatures
