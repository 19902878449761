import React from 'react'

import nProgress from 'nprogress'

class PageProgress extends React.PureComponent {
  componentDidMount () {
    nProgress.start()
  }

  componentWillUnmount () {
    nProgress.done()
  }

  render () {
    return this.props.children || null
  }
}

export default PageProgress
